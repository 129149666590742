<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
  <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Pilih rincian tagihan yang akan dibuat
            </p>
          </div>
          <div class="card-body padding-t-0">
            <template v-for="row in dataMak" :key="row.id">
              <span class="text-fl fs-upper400">{{ row.uraian }} </span>
              <p class="text-right mb-10">{{ $filters.formatRp(row.biaya) }} &nbsp;
                <i class="fa fa-plus-circle text-success fs-18" v-on:click="pilihMak(row.id_mak)"></i></p>
            </template>
          </div>
        </div>
        <div class="card" v-show="inputRinci.length != 0">
          <div class="card-header card-warning padding-bt-10">
            <p id="idExsampel">
              Tagihan yang dipilih
            </p>
          </div>
          <div class="card-body">
            <template v-for="(row, index) in inputRinci" :key="row.id">
              <span class="text-fl fs-upper400"> {{ row.uraian }} </span>
              <p class="text-right mb-10"> {{ $filters.formatRp(row.biaya) }}&nbsp;
                <i class="fa fa-minus-circle text-danger fs-18" v-on:click="deleteMak(index)"></i>
              </p>
            </template>
            <button class="btn btn-success box-shadow btn-rounded mt-3"  v-on:click="konfirmasiTagihan">Proses Slip Tagihan</button>
          </div>
        </div>
      </div>
  </div>
  <div class="modal fade bs-example-modal-default" tabindex="-1" role="dialog" aria-labelledby="myDefaultModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              class="fa fa-times"></span></button>
          <h5 class="modal-title" id="myDefaultModalLabel">Konfirmasi</h5>
        </div>
        <div class="modal-body">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-12 col-xs-12"> <strong>{{ rowMak.uraian }}</strong>
                <br>
                <p class="text-muted">Rp. {{ $filters.formatRp(rowMak.biaya) }}</p>
              </div>
              <!--
              <div class="col-md-4 col-xs-6 b-r"> <strong>Mobile</strong>
                <br>
                <p class="text-muted">+90 (0241) 256-52-58</p>
              </div>
              <div class="col-md-4 col-xs-6 b-r"> <strong>Email</strong>
                <br>
                <p class="text-muted">john-doe@domain.com</p>
              </div>
              <div class="col-md-2 col-xs-6"> <strong>Location</strong>
                <br>
                <p class="text-muted">Ankara</p>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
          <button type="button" v-on:click="tambahMak()" class="btn btn-success">Tambahkan ke slip tagihan</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade konfirmasi-modal" tabindex="-1" role="dialog" aria-labelledby="myDefaultModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              class="fa fa-times"></span></button>
          <h5 class="modal-title" id="myDefaultModalLabel">Konfirmasi</h5>
        </div>
        <div class="modal-body">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-12 col-xs-12"> <strong>Pastikan rincian dan jumlah tagihan yang dibuat sudah sesuai</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">No, Batalkan</button>
          <button type="button" v-on:click="prosesTagihan()" class="btn btn-success">Yes, Proses tagihan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import Loading from 'vue-loading-overlay'
import { getRincian, addTagihan } from '@/services/keu.service'
import { useStore } from 'vuex'
export default {
  name: 'TahihanTambah',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      dataMak: [],
      rowMak: {},
      inputRinci: [],
      isRegister: false,
      isLoading: false,
      fullPage: true
    }
  },
  created () {
    this.handleRincian()
  },
  methods: {
    async handleRincian () {
      this.isLoading = true
      getRincian({ nipd: this.userName }).then(response => {
        this.dataMak = response.records
        this.isLoading = false
      }).catch(error => {
        this.akunSpada = (error.response) ? {} : {}
        this.isLoading = false
      })
    },
    async pilihMak (id) {
      $('.bs-example-modal-default').modal('show')
      const data = this.dataMak
      var valObj = data.filter(function (elem) {
        if (elem.id_mak === id) return elem
      })
      if (valObj.length > 0) {
        this.rowMak = valObj[0]
      }
    },
    async tambahMak () {
      const rinci = {
        id: this.rowMak.id_mak,
        uraian: this.rowMak.uraian,
        biaya: this.rowMak.biaya
      }
      this.inputRinci.push(rinci)
      $('.bs-example-modal-default').modal('hide')
    },
    async deleteMak (index) {
      this.inputRinci.splice(index, 1)
    },
    async konfirmasiTagihan () {
      const rinci = this.inputRinci
      if (rinci.length > 0) {
        $('.konfirmasi-modal').modal('show')
      } else {
        $.toast({
          heading: 'Error !',
          text: 'Tidak ada rincian yang ditambah',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    },
    async prosesTagihan () {
      const params = {
        nipd: this.userName,
        rincian: this.inputRinci
      }
      this.isLoading = true
      addTagihan(params).then(response => {
        if (response.error_code !== 200) {
          $.toast({
            heading: 'Error !',
            text: response.messages,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
        } else {
          $.toast({
            heading: 'Good job!',
            text: 'Transaksi berhasil di proses',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
          setTimeout(() => {
            this.$router.push('/keuangan/tagihan')
          }, 3000)
        }
        this.isLoading = false
        $('.konfirmasi-modal').modal('hide')
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
